import { Button } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import {Col, Row} from 'shards-react';
import { Container } from "shards-react";

class FooterComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
         
        };
    }


    toggleHamburger = () => {
        document.querySelector('.navbar-mobile').classList.toggle('showNav');
        document.querySelector('.ham').classList.toggle('showClose');
    }

    render() {

        return (
      
      
                <Container fluid className="footer-container">
                    <Row className="footer-row">
                        <Col sm={3} className="footer-col h3-light-left">
                            <h3>Sitemap</h3>
                            <ul>
                                <li><Link to={'../over-ons'}>Over ons</Link></li>
                                <li><Link to={'../audio'}>Audio</Link></li>
                                <li><Link to={'../licht'}>Light</Link></li>
                                <li><Link to={'../special-fx'}>Special FX</Link></li>
                                <li><Link to={'../dansvloer'}>Dansvloer</Link></li>
                                <li><Link to={'../silent-disco'}>Silent Disco</Link></li>
                                <li><Link to={'../partners'}>Partners</Link></li>
                            </ul>

                        </Col>
                        <Col sm={3} className="footer-col h3-light-left p-light-left">
                        <h3>Silentdiscobenelux</h3>
                        <p>
                        Annuleren is tot 2 werkdagen voor verzending altijd kosteloos en zonder opgave van reden mogelijk.
<br/><br/>
Geen borg en achteraf betalen! <br/>
Heldere voorwaarden & prettige communicatie.


                        </p>
                            
                        </Col>
                        <Col sm={3} className="footer-col h3-light-left p-light-left">
                        <h3>Contact</h3>
                        <p>
                            Eeckersteghe 8,<br/>
                            6181 KZ Elsloo
                        </p>
                        <p>
                            info@dmsounds.nl
                        </p>
                        <p>
                        +31 (0)6 424 649 90 <br/>
+31 (0)6 153 365 26
                        </p>
                            
                        </Col>
                        <Col sm={3} className="footer-col h3-light-left">
                        <h3>Bestellen</h3>
                        <ul>
                                <li><a target="_blank" href={'https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/dm-sounds%2FSilent%20disco%20benelux%20-%20Algemene%20voorwaarden.pdf?alt=media&token=b09bbcb6-6b04-4228-ae37-a7027253c123'}>Algemene voorwaarden</a></li>
                                <li><a target="_blank" href={'https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/dm-sounds%2FSilent%20disco%20benelux%20-%20Privacy%20policy.pdf?alt=media&token=c23d3577-d0a5-4da2-84ed-6ad9e9685c98'}>Privacy Voorwaarden</a></li>
                                <li><a target="_blank" href={'https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/dm-sounds%2FSilent%20disco%20benelux%20-%20Veelgestelde%20vragen.pdf?alt=media&token=20b2f352-4aa5-4ef0-9e33-41faf7018250'}>Vragen</a></li>
                               
                            </ul>
                            
                        </Col>
                        <Col sm={12} className="credits-col">
                        DM Sounds 2021 (c)  Design & Development By PixelPro’s - All Rights Reserved
                        </Col>
                    </Row>
                    
                </Container>
       
        );
    }
}

export default FooterComponent;
