import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Firebase, { storage } from 'firebase';

class ThankyouComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            products:[],
            owners:[],
            pickup:true,
            ownerNames:[],
            pickupStatus:'',
            orderId:''

      

        };
    }

    getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
      }
      products = [];
    sendOrderNotification(){
        console.log('owner function stared')
        this.state.owners.map((owner)=>{
            let headers = new Headers();
            console.log('owner function stared')
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            headers.append('Origin','https://beterbijons.nl/');
            fetch("https://us-central1-web-app-bbo-prod.cloudfunctions.net/SendOwnerNotification", {
                method: "POST",
                headers: headers,
                body: JSON.stringify({email: owner})
              })
              .then(res => {
                  console.log(res)
                  
                
                return res.json();
              }).then(()=>{
              
                  //localStorage.clear();
              })
              console.log(owner+"owner is");

              if(owner == 'info@koffieenanders.nl'){
                  console.log(owner+"owner is")
                  console.log(this.products.length+'lengte is')
                for(let i =0; i< this.products.length;i++){

                    if(this.products[i].officialName == 'Koffie & Anders'){
                  
                      fetch("https://us-central1-web-app-bbo-prod.cloudfunctions.net/CreateDraftShopify", {
                          method: "POST",
                          headers: headers,
                          body: JSON.stringify({id: this.products[i].variantId,amount:this.products[i].amount})
                        })
                    }
      
              }
            }
         
        })
       
        if(localStorage.getItem('pickup') == 'false'){
            let headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            headers.append('Origin','https://beterbijons.nl/');
        
            console.log('here')
                    fetch("https://us-central1-web-app-bbo-prod.cloudfunctions.net/orderNotificationPerBakfiets", {
                        method: "POST",
                        headers: headers,
                        body: JSON.stringify({name: localStorage.getItem('name'),email:localStorage.getItem('mail'),phone: localStorage.getItem('phone'),item:this.state.products,street: localStorage.getItem('street'),city: localStorage.getItem('city')})
                      }).then(() => {
                        localStorage.removeItem('pickup');
                        localStorage.removeItem('deliver');
                        
                      
                  
                    });
                   }
     
        
      
    }

    createNewUser(){
        Firebase.auth().createUserWithEmailAndPassword(localStorage.getItem('mail'),localStorage.getItem('pass')).then(function(user){
            console.log(user.user.uid);
            console.log('gebruiker aangemaakt')
            var key = Firebase.database().ref().child('/users/'+user.user.uid).push().key;
            Firebase.database().ref('/users/'+user.user.uid).set({
                id: user.user.uid,
                name:   localStorage.getItem('name'),
                phone: localStorage.getItem('phone'),
                mail: localStorage.getItem('mail'),
                role:'user',
                userUID:user.user.uid,
            });
            toast.success('Het account is succesvol aangemaakt.')
        }).catch((error)=>{
            console.log(error.message)
            if(error.message == 'The email address is already in use by another account.'){
                toast.error('Er is geen nieuw account aangemaakt, het account bestaat al.')
            }
        })
    }

    makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
      charactersLength));
       }
       return result;
      }
      

sendInvoice(){
let shoppingBag=JSON.parse(localStorage.getItem('bagItems'));

    console.log(localStorage.getItem('pickup') )

    let headers = new Headers();


    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    //headers.append('Origin','http://localhost:3000');
    headers.append('Origin','https://beterbijons.nl/')
    headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")

   
    fetch("https://us-central1-web-app-bbo-prod.cloudfunctions.net/CreatePdfMaastricht", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            name: localStorage.getItem('name'),
            email:localStorage.getItem('mail'),
            phone: localStorage.getItem('phone'),
            items:this.state.products,
            total:localStorage.getItem('totalWithShipment'),
            numberId:localStorage.getItem('orderNumber'),
            shipping:localStorage.getItem('shipment'),
            street:  localStorage.getItem('street'),
            city:    localStorage.getItem('city'),
            provincie:  localStorage.getItem('provincie'),
            coupon: localStorage.getItem('coupon')
            })
      }).then(()=>{
        shoppingBag.map((product)=>{
            console.log(product)
            let ref = Firebase.database().ref('/cities/-onefoefw/shops/'+product.winkelier+'/products/'+product.id);
            ref.on('value' , snapshot => {
    
                let state = snapshot.val();
       
                Firebase.database().ref('/cities/-onefoefw/shops/'+product.winkelier+'/products/'+product.id).update({
                    stock: (Number(state.stock)-Number(product.amount)),
                   
            
                })
            })
      
           
        })
      })
      .then(res => {
        localStorage.removeItem('total');
        localStorage.removeItem('bagItems');
        localStorage.removeItem('menuActive');
        localStorage.removeItem('name');
        localStorage.removeItem('shoppingBag');
        localStorage.removeItem('mail');
        localStorage.removeItem('tokens');
        localStorage.removeItem('amount');
        localStorage.removeItem('clientId');
        localStorage.removeItem('phone');
        localStorage.removeItem('orderNumber');
        localStorage.removeItem('pickup');
        localStorage.removeItem('createUserAccount');
        localStorage.removeItem('pass');
        localStorage.removeItem('bankSelected');
        localStorage.removeItem('coupon')
       // this.sendOrderNotification();
        
          
      
 
      })
}

pickupStatus ;
    componentDidMount(){

   

        localStorage.removeItem('total');
        localStorage.removeItem('bagItems');
        localStorage.removeItem('menuActive');
        localStorage.removeItem('name');
        localStorage.removeItem('shoppingBag');
        localStorage.removeItem('mail');
        localStorage.removeItem('tokens');
        localStorage.removeItem('amount');
        localStorage.removeItem('clientId');
        localStorage.removeItem('phone');
        localStorage.removeItem('orderNumber');
        localStorage.removeItem('pickup');
        localStorage.removeItem('createUserAccount');
        localStorage.removeItem('pass');
        localStorage.removeItem('bankSelected');
        localStorage.removeItem('coupon');
        localStorage.removeItem('totalWithShipment');
        localStorage.removeItem('orderNumber');
        localStorage.removeItem('shoppingCartAmount');
     

    }






    render() {
        

        return (
     
            <Container fluid>
         
                    <Row className="background-color-fluid thank-you-row mobile-padding-top-xxl-large">
                        <Col sm={6} className={'thank-you-col h2-light-left thank-you-col-image'}>
                            <h2>Bedankt voor je bestelling!</h2>
                        </Col>
                        <Col sm={6} className={'thank-you-col h2-light-left'}>
                            <h2>Hoe nu verder?</h2>
                            <div className="thank-you-block h3-light-left  p-light-left">
                                <h3 className="firstblock-thankyou">1. Je krijgt een orderbevestiging per e-mail.</h3>
                                <p>Binnen enkele ogenblikken sturen wij jou een orderbevestiging per mail voor order: {this.state.orderId}.</p>
                            </div>
                            <div className="thank-you-block h3-light-left  p-light-left">
                                <h3 className="secondblock-thankyou">2. Wij gaan aan de slag.</h3>
                               <p>Wij gaan je bestelling inpakken en optijd naar je opsturen. Zodat jij verzekerd bent van een mooie avond!</p>
                            </div>
                            <div className="thank-you-block h3-light-left  p-light-left">
                                <h3 className="thirdblock-thankyou">3. Retourneren?</h3>
                               <p>Stuur de bestelling de volgende werkdag na ons terug om ervoor te zorgen dat andere ook hun bestelling tijdig ontvangen.</p>
                            </div>
                        </Col>
                    </Row>
        
            </Container>
 

        );
    }
};

export default ThankyouComponent;