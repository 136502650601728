import React from "react";
import { Container, Row, Col } from "shards-react";
import AdminPanelIndex from "../../components/adminpanel";

import PageTitle from "../../components/common/PageTitle";
import FooterComponent from "../../components/Footer/FooterComponent";
import FrontendHomeComponent from "../../components/frontend";
import HeaderComponent from "../../components/Header/headerComponent";
import SubHeaderComponent from "../../components/Header/subHeaderComponent";
import ProductsIndex from "../../components/products";



const FrontendHome = () => (
    <div>
        <SubHeaderComponent/>
        <HeaderComponent />
    <FrontendHomeComponent />

    <FooterComponent/>
    </div>
);

export default FrontendHome;
