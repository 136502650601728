import React from "react";
import FooterComponent from "../../components/Footer/FooterComponent";

import AboutUsComponent from "../../components/frontend/about-us";
import AudioComponent from "../../components/frontend/audioComponent";
import Lightsomponent from "../../components/frontend/lightsComponent";
import SpecialFxComponent from "../../components/frontend/specialFxComponent";
import HeaderComponent from "../../components/Header/headerComponent";
import SubHeaderComponent from "../../components/Header/subHeaderComponent";



const SpecialFxView = () => (
    <div>
<SubHeaderComponent/>
<HeaderComponent/>
    <SpecialFxComponent />

    <FooterComponent/>
    </div>
);

export default SpecialFxView;
