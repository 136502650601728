import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import toast, { Toaster } from 'react-hot-toast';

class ContactComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingForm:false
      

        };
    }

    sendContactForm(){
        this.setState({
            loadingForm:true
        })
        if(this.state.name != '' &&this.state.phone != '' && this.state.mail != '' ){
            let headers = new Headers();
    
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin','http://localhost:3000');
        //headers.append('Origin','https://p-your.web.app')
        headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
        fetch("https://us-central1-cashierr-pixelpros.cloudfunctions.net/contactFromDMSounds", {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                companyname: this.state.companyName, 
                name: this.state.name,
                email:this.state.mail,
                phone:this.state.phone,
                desc:this.state.desc,
            
            })
        }).then(()=>{
            this.setState({
                companyName: '', 
                name:'',
                mail:'',
                phone:'',
                desc:'',
                loadingForm:false
            })
            toast.success('Verzenden is gelukt!')
           
        })
        
       
    
        
    }else{
        toast.error('Verzenden is mislukt, vul alle velden en probeer het opnieuw!')
    }
    }


    render() {
        

        return (
     
        <Container fluid >
            <Toaster/>
            <Row className="background-color-fluid contact-row mobile-padding-top-xxl-large product-page-top">
                <Col sm={12} className="h2-light-center p-light-center">
                    <h2><span className="red-slashes">//</span> CONTACT</h2>
                    <p>
                        <br/>
                   <b>Vragen of speciale wensen?<br/> Vul onderstaand formulier in, Dan nemen wij zo snel mogleijk contact met u op.</b>
                    </p>
                 

                </Col>
                <Col sm={1}></Col>
                <Col sm={10}>
                    <form className="contact-form">
                        <input placeholder="Bedrijfsnaam" type="text" value={this.state.companyName} onChange={(event)=>this.setState({companyName: event.target.value})} />
                        <input placeholder="Naam" type="text" value={this.state.name} onChange={(event)=>this.setState({name: event.target.value})} />
                        <input placeholder="E-mail" type="text" value={this.state.mail} onChange={(event)=>this.setState({mail: event.target.value})} />
                        <input placeholder="Telefoon" type="text" value={this.state.phone} onChange={(event)=>this.setState({phone: event.target.value})} />
                        <textarea placeholder="Opmerkingen.." value={this.state.desc} onChange={(event)=>this.setState({desc: event.target.value})}/>
                        <Button className="button-red-left custom-contact" onClick={()=>this.sendContactForm()}>{this.state.loadingForm ?<Loader type="Bars" color="#fff" height={20} width={20} />: 'Verzenden'}</Button>
                        
                    </form>
                </Col>
                <Col sm={1}></Col>
                
               
            </Row>
            <Row className="background-image-fluid social-media">
            <Col sm={3}>

</Col>
                <Col sm={6}  className="social-media-block  h2-light-center">
                    <h2>VOLG ONS OP SOCIAL MEDIA</h2>
                    <p><a href="https://www.instagram.com/silentdiscobenelux/"><i class="fab fa-instagram"></i></a> <a href="https://www.facebook.com/silentdiscobenelux"><i class="fab fa-facebook-square"></i></a></p>
                </Col>
                <Col sm={3}>

                </Col>
            </Row>
           
            
        </Container>

        );
    }
};

export default ContactComponent;