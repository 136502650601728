import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";
import Firebase from 'firebase';

class CheckoutComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'',
            productAmount:1,
            email:'',
            firstName:'',
            lastName:'',
            phone:'',
            email:'',
            companyFirstname:'',
            companyLastname:'',
            companyZip:'',
            companyAdres:'',
            companyBTW:'',
            companyNumber:'',
            companyEmail:'',
            companyPhone:'',
            comapnyCity:'',
            company:false,
            bagItems:[],
            productImages:[],
            allTotals:0,
            particulier: true,
            zakelijk:false,
            companyName:'',
            pickUp:true,
            deliver: false,
            post:false,
            hasNoPerBakfiets:[],
            hasPerBakfiets:[],
            fcmtokens:[],
            companyImages:[],
            street:'',
            city:'',
            products:[],
            totalPayments:[],
            TermsAndConditions:false,
            createUserAccount:false,
            timeslotsOwners:[],
            pickupLocation:[],
            resultPickups:0,
            totalWithShip:0,
            provincie:'',
            postAdress:[],
            postal:''
      

        };
    }

    products = [];
    
    testShopify(){
        this.setState({
            products: JSON.stringify(localStorage.getItem('bagItems'))
        })
        console.log('prdocuts are:'+ this.products.length)

            for(let i =0; i< this.products.length;i++){
                if(this.products[i].officialName === 'Koffie & Anders'){
                   console.log("id is:"+this.products[i].variantId)
                  
                }
            }
  
          
    }

    checkProducts(){

        this.setState({bagItems:[]})
        this.setState({
            allTotals: 0,
            backUpPrice:0
        })

        this.totalPrice =0;
     
        this.products = JSON.parse(localStorage.getItem('shoppingBag')) ;
        localStorage.setItem('shoppingCartAmount', this.products.length)
     
             for(let i = 0; i< this.products.length; i++){

               if(this.products[i].page =='deal'){

    

                let products = Firebase.database().ref('/clients/-33297fbik3bjfew/deals/'+this.products[i].id);
              products.on('value' , snapshot => {
                    let state = snapshot.val();

                    let price= state.price;
                 
                
                    let addedProduct = {name:state.name,headphones:this.products[i].headphones,receivers:this.products[i].receivers,price:price,id:this.products[i].id,selectedDate:this.products[i].date}
                  //  let totalAmount = parseFloat() *this.products[i].amount;

                  console.table(addedProduct)
                   
                    this.setState(prevState =>({
                        bagItems: [...prevState.bagItems,addedProduct],
                      
                 
                    }))
               
                    this.calculateTotel((parseFloat(price.toString().replace(/,/g, '.'))))
          
                
                   
                   
                })
          
            }
                else if(this.products[i].page =='custom'){
                    let addedProduct = {name:'Eigen samenstelling',headphones:this.products[i].headphones,receivers:this.products[i].receivers,price:this.products[i].price,id:this.products[i].id,selectedDate:this.products[i].date}
            

                    console.table(addedProduct)
                    
                        this.setState(prevState =>({
                            bagItems: [...prevState.bagItems,addedProduct],
                        
                    
                        }))
                
                        this.calculateTotel((parseFloat(this.products[i].price.toString().replace(/,/g, '.'))))
            
                }
            }

            
              
    }

    calculateTotel(newPayment){

        this.setState({
             allTotals:this.state.allTotals+ newPayment
         })

     
 
         
 
     }
totalPayments=[];

generateOrderId(){
    let newOrderNumber =this.state.totalPayments.length+1;
    localStorage.setItem('orderNumber','ORDER00'+newOrderNumber);

}
   

    componentDidMount= async() =>{
       this.setState({
        totalWithShip: Number(localStorage.getItem('total')),
       }) 
       localStorage.setItem('shipment', 0);
       localStorage.setItem('totalWithShipment',(Number(localStorage.getItem('total'))));

        window.scrollTo(0, 0);

        let payments =await  Firebase.database().ref('/clients/-33297fbik3bjfew/orders/');
        payments.on('value' , snapshot => {
            snapshot.forEach((childSnap) => {
                let payment = childSnap.val();
    
                //let newImage = {id: this.products[i].winkelier,url:newImages.url, productId:this.products[i].id}
                
                    this.setState(prevState => ({
                        totalPayments: [...prevState.totalPayments, payment],
                    }));
                  
                    
            });
      

        })
  
            let newOrderNumber =this.state.totalPayments.length+1;
            console.log(newOrderNumber)
            localStorage.setItem('orderNumber','ORDER00'+newOrderNumber);
        


        if(localStorage.getItem('shoppingBag') != null){
            await this.checkProducts();
 
                  
            
        }


        localStorage.setItem('pickup',true)

      



     




    }

    changePickUp(){
        if(this.state.pickUp === true){
            this.setState({
                pickUp: false,
                deliver: false,
                post:false
            })
       
            localStorage.setItem('deliver',false);
            localStorage.setItem('pickup',false);
            localStorage.setItem('post',false);
        }else if( this.state.pickUp === false){
            this.setState({
                pickUp: true,
                deliver:false,
                post:false,
                totalWithShip: Number(localStorage.getItem('total')) ,
            })
            localStorage.setItem('deliver',false);
            localStorage.setItem('pickup',true);
            localStorage.setItem('post',false);
            localStorage.setItem('shipment', 0);
            localStorage.setItem('totalWithShipment',(Number(localStorage.getItem('total'))));
        }

    }

    createUserAccount(){
        if(this.state.createUserAccount === true){
            this.setState({
                createUserAccount: false
            })
            localStorage.setItem('createUserAccount',false);
        }else if(this.state.createUserAccount === false){
            localStorage.setItem('createUserAccount',true);
            this.setState({
                createUserAccount:true
            })
        }
    }

    userCreatePass(event){
        this.setState({
            passwordUserAccount:event
        })

        localStorage.setItem('pass',event)
    }

    changeDeliver(){
        if(this.state.deliver === true){
            this.setState({
                pickUp: false,
                deliver: false,
                post:false
            })
       
            localStorage.setItem('deliver',false);
            localStorage.setItem('pickup',false);
            localStorage.setItem('post',false);
        }else if( this.state.deliver === false){
            this.setState({
                pickUp: false,
                deliver:true,
                post:false,
                totalWithShip: Number(localStorage.getItem('total')) +500 ,
            })
            localStorage.setItem('deliver',true);
            localStorage.setItem('pickup',false);
            localStorage.setItem('post',false);
            localStorage.setItem('shipment', 500);
            localStorage.setItem('totalWithShipment',(Number(localStorage.getItem('total')) +500));
        }


    }
    changePost(){
        if(this.state.post === true){
            this.setState({
                pickUp: false,
                deliver: false,
                post:false
            })
       
            localStorage.setItem('deliver',false);
            localStorage.setItem('pickup',false);
            localStorage.setItem('post',false);
        }else if( this.state.post === false){
            this.setState({
                pickUp: false,
                deliver:false,
                post:true,
                totalWithShip: Number(localStorage.getItem('total')) +695 ,
            })
            localStorage.setItem('deliver',false);
            localStorage.setItem('pickup',false);
            localStorage.setItem('post',true);
            localStorage.setItem('shipment', 695);
            localStorage.setItem('totalWithShipment',(Number(localStorage.getItem('total')) +695));
        }


    }


  /*  fetchStripe(){
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Origin','http://localhost:3000');
        headers.append('Access-Control-Allow-Methods', "POST, GET, OPTIONS, DELETE, PUT")
       fetch("https://us-central1-web-app-bbo-prod.cloudfunctions.net/helloCors", {
      
            method: 'POST',
        headers: headers,
        body: JSON.stringify({amount:2330})
          }).then(function(response) {
              console.log('here')
              console.log(response)
            return response.json();
          }).then(function(responseJson) {
  
            var clientSecret = responseJson.client_secret;
            console.log('secret is:'+clientSecret)

            // Call stripe.confirmIdealPayment() with the client secret.
          });

         // localStorage.setItem('amount', (this.state.allTotals.toFixed(2)*100))
    }*/


changeName(event){
    this.setState({
        firstName:event}
        ) ;

        let totalprice= localStorage.getItem('total')

    localStorage.setItem('name',event)
    localStorage.setItem('amount', totalprice)
    localStorage.setItem('tokens',JSON.stringify(this.state.fcmtokens))
}

changePhone(event){
    this.setState({
        phone:event}
        ) ;

    localStorage.setItem('phone',event)

}

changeMail(event){
    this.setState({
        email:event}
        ) ;

    localStorage.setItem('mail',event)

}

changeStreet(event){
    this.setState({
        street:event}
        ) ;

    localStorage.setItem('street',event)

}
changeCity(event){
    this.setState({
        city:event}
        ) ;

    localStorage.setItem('city',event)

}

changeProvincie(event){
    this.setState({
        provincie:event}
        ) ;

    localStorage.setItem('provincie',event)

}

checkTermsAndConditions(){
    this.generateOrderId();
    if(this.state.TermsAndConditions === true){
        this.setState({
            TermsAndConditions: false
        })
    }else if(this.state.TermsAndConditions === false){
        this.setState({
            TermsAndConditions:true
        })
    }
}


checkCompany(){
    if(this.state.zakelijk === true){
        this.setState({
            zakelijk: false
        })
    }else if(this.state.zakelijk === false){
        this.setState({
            zakelijk:true
        })
    }
}
    

 getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }


  writetoDb(){
    var key = Firebase.database().ref().child('/clients/-33297fbik3bjfew/orders/').push().key;
    Firebase.database().ref('/clients/-33297fbik3bjfew/orders/'+key).set({
        id: key,
        total:  this.state.totalWithShip,
        items:this.state.bagItems,
        status:'unpaid',
        orderId:localStorage.getItem('orderNumber'),
        name: this.state.firstName,
        email:this.state.email,
        phone:this.state.phone,
        street:this.state.street,
        postal:this.state.postal,
        city:this.state.city,
        country:this.state.provincie,
        coupon:'not-active',
        newOrder:true,
        orderStatus:'In progress'

    }).then((data)=>{
        console.log('done')
    }).catch((error)=>{
        //error callback
        console.log('error ' , error)
    })

  }
  





    render() {
        

        return (

        <Container fluid>
        
            <Row className="background-color-fluid thank-you-row mobile-padding-top-xxl-large">
                <Col sm={6} className="checkout-detail h2-light-left">
                
                <span>
               
                    
                <h2 className="contact-information-checkout">Contact informatie</h2>
                
          
               

                <form >
                    <span className="input-full-width">
                    <label>Je naam</label><br/>
                        <input type="text" value={this.state.firstName} onChange={(event) => this.changeName(event.target.value)} required/>
                    </span>
                    <span className="input-full-width">
                        <label>E-mail</label><br/>
                        <input type="text" value={this.state.email} onChange={(event) => this.changeMail(event.target.value)} required/>
                    </span>
                    <span className="input-full-width">
                        <label>Telefoonnummer</label><br/>
                        <input type="text" value={this.state.phone} onChange={(event) => this.changePhone(event.target.value)} />
                    </span>
                    
                    <span>
                      <span className="input-full-width">
                      <label>Straatnaam + huisnummer</label><br/>
                      <input type="text" value={this.state.street} onChange={(event) => this.changeStreet(event.target.value)} />
                  </span>
                  <span className="input-full-width">
                    <label>Postcode</label><br/>
                    <input type="text" value={this.state.postal} onChange={(event) => this.setState({postal:event.target.value})} />
                </span>
                    <span className="input-full-width">
                    <label>Woonplaats</label><br/>
                    <input type="text" value={this.state.city} onChange={(event) => this.changeCity(event.target.value)} />
                </span>
                <span className="input-full-width">
                    <label>Land</label><br/>
                    <select onChange={(event)=> this.changeProvincie(event.target.value)}>
                        <option selected="selected" disabled="disabled">Selecteer een land</option>
                        <option value="Nederland">Nederland</option>
                        <option value="België">België</option> 
                    </select>
                </span>

               

             
                </span>

                </form>
               
                

            </span>
                

                
                </Col>
                <Col sm={6} className="checkout-detail h2-light-left p-light-left">
                    <h2>Mijn feestlijst</h2>
                   <ul className="checkout-shoppinglist">
                       {this.state.bagItems.map((item)=>(
                            <li><span className="product-title-shoppinglist-checkout">{item.name}</span><span className="product-amount-shoppinglist-checkout">{(new Date(item.selectedDate)).getDate()+'-'+((new Date(item.selectedDate)).getMonth()+1)+'-'+(new Date(item.selectedDate)).getFullYear()}</span><span className="product-price-shoppinglist-checkout">€ {parseFloat(item.price).toFixed(2)}</span></li>
                       ))}
                      
                       
                   </ul><br/>
                   <hr/>
                   <p>Korting: <span className="checkout-shoppinglist-total">{localStorage.getItem('coupon') == 'active' ? '5%': '0%'}</span></p>
                   <p>Verzendkosten: <span className="checkout-shoppinglist-total">{this.state.pickUp ? '-':null} {this.state.deliver ? '€ 5,00':null} {this.state.post ? '€ 6,95':null}</span></p>
                   
                   <p><b>Totaal:</b><span className="checkout-shoppinglist-total"><b>€ {  parseFloat(this.state.totalWithShip).toFixed(2)}</b></span></p>
                   <span className="check-box-checkout"><input type="checkbox" onClick={()=>this.checkTermsAndConditions()} checked={this.state.TermsAndConditions}/><label >Ik accepteer de <a href="https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/dm-sounds%2FSilent%20disco%20benelux%20-%20Algemene%20voorwaarden.pdf?alt=media&token=b09bbcb6-6b04-4228-ae37-a7027253c123" target="_blank">algemene voorwaarden</a></label></span>
                   <form  method="post" action={"https://us-central1-cashierr-pixelpros.cloudfunctions.net/DmSoundsPayment"} id="hook-form" >
                   <input style={{display:'none'}} required type="text" name="totalPrice" value={parseFloat(this.state.totalWithShip).toFixed(2).toString()}/>
                   <input style={{display:'none'}} required type="text" name="bookingId" value={localStorage.getItem('orderNumber')}/>
                   </form>
                 
                  {/*} <Button className="pay-bill-button">Afrekenen</Button>*/}
                   {this.state.TermsAndConditions?
                   this.state.name != '' ||  this.state.email != '' || this.state.phone!= '' ?
                      <button type="submit" className="pay-bill-button" form="hook-form" onClick={()=>this.writetoDb()}>Betaal</button>:
                    null:
                   <span className="termsAndConditions">
                        Accepteer de algemene voorwaarden om te kunnen betalen
                    </span>
                    }
                </Col>
            </Row>

            
        </Container>

        );
    }
};

export default CheckoutComponent;