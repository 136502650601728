import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";
import toast, { Toaster } from 'react-hot-toast';
import Firebase from 'firebase';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Redirect } from 'react-router-dom';
class ProductpageComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id:'',
            productAmount:1,
            currenShoppingBag:[],
            winkelier:'',
            plaats:'',
            product:[],
            variations: false,
            variation:[],
            price:0,
            images:[],
            stock:0,
            variantChosen:'',
            information:[],
            products:[],
            noProducts:false,
            productImages:[],
            priceSet:false,
            variantId:'',
            officialName:'',
            description:'',
            imageoverView:false,
            cantShip:true,
            productInformation:[],
            receivers:1,
            headphones:1,
            priceHeadphones:4.84,
            priceReceivers:18.15,
            selectedDate:new Date(),
            redirect:false
      

        };
    }


    Products = [];
    splittedItem = [];

    executeScroll = () => this.anyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })




    componentDidMount(){
      
       
               


      /* this.splittedItem = this.props.id.split("?");

        this.setState({
            id: this.splittedItem[1].split("id=")[1],
        })

        if(localStorage.getItem('shoppingBag') != null){
            this.Products = JSON.parse(localStorage.getItem('shoppingBag')) ;
            localStorage.setItem('shoppingCartAmount', this.Products.length)
        }

        let products = Firebase.database().ref('/clients/-33297fbik3bjfew/products/'+this.splittedItem[1].split("id=")[1]);
        products.on('value' , snapshot => {
            let state = snapshot.val();

            this.setState({
                product: state
            })

            console.log(state)

            
      
           
        })
*/


    }

    AddToCart(){

    
    
 
    
            this.Products.push({page:'custom',id: '-Custom',headphones:this.state.headphones,receivers:this.state.receivers,date:new Date(this.state.selectedDate),price:(this.state.priceHeadphones+this.state.priceReceivers)});
        
        

      


        localStorage.setItem('shoppingBag',JSON.stringify(this.Products));
        localStorage.setItem('shoppingCartAmount', this.Products.length);


        const reaction = ()=>( <div> Het product is succesvol toegevoegd! <a href={'../winkelmand'}> Bekijk</a></div>)
        toast.success(reaction);
        this.setState({
            redirect:true
        })
    }


    minProductAmount(amount, selectedProduct){
        console.log('groter dan')
        if((amount -1) >0){
console.log('groter dan')
        let newAmount = amount - 1;

        for(let i = 0; this.state.products.length; i++){
            if(selectedProduct.id == this.state.products[i].id){

                    let products = [...this.state.products];
                    // 2. Make a shallow copy of the item you want to mutate
                    let product = {...this.state.products[i]};
                    console.log(product)
                    // 3. Replace the property you're intested in
                    product.amount = newAmount;
                   
                    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                    products[i] = product;
                    // 5. Set the state to our new copy
                    this.setState({products});

                    return
            }

        }
    }
        
    }

    addProductAmount(amount, selectedProduct){
      if(this.state.product.activeVariations ){
       if(amount +1 < this.state.stock) {
        let newAmount = amount +1;

        for(let i = 0; this.state.products.length; i++){
            if(selectedProduct.id == this.state.products[i].id){

                    let products = [...this.state.products];
                    // 2. Make a shallow copy of the item you want to mutate
                    let product = {...this.state.products[i]};
                    console.log(product)
                    // 3. Replace the property you're intested in
                    product.amount = newAmount;
                   
                    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                    products[i] = product;
                    // 5. Set the state to our new copy
                    this.setState({products});

                    return
            }

        }
       }

      }else{
        if(amount +1 < this.state.product.stock) {
            let newAmount = amount +1;
    
            for(let i = 0; this.state.products.length; i++){
                if(selectedProduct.id == this.state.products[i].id){
    
                        let products = [...this.state.products];
                        // 2. Make a shallow copy of the item you want to mutate
                        let product = {...this.state.products[i]};
                        console.log(product)
                        // 3. Replace the property you're intested in
                        product.amount = newAmount;
                       
                        // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                        products[i] = product;
                        // 5. Set the state to our new copy
                        this.setState({products});
    
                        return
                }
    
            }
           }
           
            
      } 
        

    }

    headPhonemin(){
        this.setState({
            priceHeadphones:0
        })
        let newAmount = this.state.headphones -1;
        this.setState({headphones: this.state.headphones >0? this.state.headphones -1:0})
        
        if(newAmount < 51){
            this.setState({
                priceHeadphones: newAmount *4.84
            })
        }
        else if(newAmount > 50 && newAmount < 101){
            this.setState({
                priceHeadphones: newAmount *4.34
            })
        }
        else if(newAmount > 100 && newAmount < 251){
            this.setState({
                priceHeadphones: newAmount *3.85
            })
        }
        else if(newAmount > 250){
            this.setState({
                priceHeadphones: newAmount *3.61
            })
        }

    
    
    }

    headPhoneadd(){
    
        this.setState({
            priceHeadphones:0
        })
        let newAmount = this.state.headphones +1;
        this.setState({headphones: this.state.headphones +1})
        
        if(newAmount < 51){
            this.setState({
                priceHeadphones: newAmount *4.84
            })
        }
        else if(newAmount > 50 && newAmount < 101){
            this.setState({
                priceHeadphones: newAmount *4.34
            })
        }
        else if(newAmount > 100 && newAmount < 251){
            this.setState({
                priceHeadphones: newAmount *3.85
            })
        }
        else if(newAmount > 250){
            this.setState({
                priceHeadphones: newAmount *3.61
            })
        }

    
    
    }

    receiverMin(){
        this.setState({
            priceReceivers:0
        })
        let newAmount = this.state.receivers -1;
        this.setState({receivers: this.state.receivers >0? this.state.receivers -1:0})
        
        if(newAmount == 1){
            this.setState({
                priceReceivers: 18.15 
            })
        }
       else  if(newAmount == 2){
            this.setState({
                priceReceivers: 35
            })
        }
        else  if(newAmount== 3){
            this.setState({
                priceReceivers: 45
            })
        }

    
    
    }

    receiverAdd(){
    
        this.setState({
            priceReceivers:0
        })
        let newAmount = this.state.receivers +1;
        this.setState({receivers: this.state.receivers +1})
        
        if(newAmount == 1){
            this.setState({
                priceReceivers: 18.15 
            })
        }
       else  if(newAmount == 2){
            this.setState({
                priceReceivers: 35
            })
        }
        else  if(newAmount == 3){
            this.setState({
                priceReceivers: 45
            })
        }

    
    
    }
    selectDate(event){
        this.setState({
            selectedDate:event
        })

        console.log(event)
 
    }
    


    render() {
        if( this.state.redirect){
            return <Redirect to={'/winkelmand'} /> 
           }
        

        return (
     
            <Container fluid>
                    
            <Row  className="background-color-fluid contact-row mobile-padding-top-xxl-large product-page-top">
                <Col sm={6} className="shop-highlighted-product-image shop-detail-image">
                   <img  src={'https://firebasestorage.googleapis.com/v0/b/cashierr-pixelpros.appspot.com/o/dm-sounds%2Fimages%2Flarge-items.jpg?alt=media&token=da45e749-7ab6-4ff0-bd9a-f4b705816e03'} />
                </Col>
                <Col sm={6} className="shop-product-detail h2-light-left p-light-left">
                    <h2>Stel je eigen set samen!</h2>
                    <p dangerouslySetInnerHTML={{ __html:this.state.product.productDesc}}></p>

                    <p><br/><span className="price-product-detail">€ {parseFloat((this.state.priceHeadphones+this.state.priceReceivers)).toFixed(2)}</span>  incl. BTW</p>
                    <br/>  <br/>
                    <div className='amount-set'>
                    <p><b>Aantal hoofdtelefoons</b></p>    
                    <span className="amount-add"><i class="fas fa-minus-circle" onClick={()=>this.headPhonemin()}></i> {this.state.headphones}  <i class="fas fa-plus-circle" onClick={()=>this.headPhoneadd()}></i></span>   
                    </div>
                    <div className='amount-set'>
                    <p><b>Aantal zenders</b></p>    
                    <span className="amount-add"><i class="fas fa-minus-circle" onClick={()=>this.receiverMin()}></i>  {this.state.receivers} {this.state.receivers <3 ?<i class="fas fa-plus-circle" onClick={()=>this.receiverAdd()}></i>:null}</span>   
                    </div>
                    <p><b>Selecteer datum</b></p>    
                    <Calendar locale={"nl-NL"} value={this.state.selectedDate}  onChange={(event)=> this.selectDate(event)}  selectRange={false} returnValue={"start"} minDate={new Date()}/>
<br/>
                    <Button  className="add-to-shoppingcart-half" onClick={()=>this.AddToCart()}>In winkelmand</Button>
                    
                </Col>
            </Row>

            <Row>
                <Col sm={12} className="shop-summary-inside">
                    {this.state.images.map((image)=>(
                        <img src={image.url} />
                    ))}
                


                </Col>
                {/*<Col sm={12} className="shop-summary-products">
                <h2>Gerelateerde producten</h2>

                {this.state.noProducts ?
                <span className="no-products">Er zijn helaas nog geen producten beschikbaar</span>

                :

                this.state.products.filter((item) => item.status != 'concept').map((product)=>(
                    <a href={"/product?plaats=maastricht?winkelier="+product.shopkeeper+"?id="+product.id}>  <div className="product-col-new add-to-card-product">
                    <div className="product-image-background-new">
                    {this.state.productImages.filter(image => product.id == image.productId).slice(0, 1).map((image)=>(
                        <div className='product-image' style={{height:'17rem',borderRadius:'5px',backgroundImage:`url(${image.url})`, backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}> </div>

))}

</div>
                    <h5 className="product-title-new-h5">{this.state.information.companyName}</h5>
                    <h4 className="product-title-new">{product.name.substr(0, 30)}</h4>
                    
                   
                    {product.activeVariations ?
                    <span>
                    {product.piceIsset ? <p className="shopkeeper-price">€ {product.price} 
                    </p> : <p className="shopkeeper-price">Vanaf: <span >€ {product.variations.slice(0,1).map((variant)=>(variant.price))}</span></p>}  </span>:
                    <p className="shopkeeper-price-new">€ {product.price.toFixed(2)} 
                    </p>}

                    </div></a>
                    ))
                    }
                </Col>*/}
            </Row>
        </Container>

        );
    }
};

export default ProductpageComponent;