import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Link } from "react-router-dom";
import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import "../src/assets/chashierr.css";
import "../src/assets/desktop-css.css";
import "../src/assets/notebook-css.css";
import "../src/assets/tablet-css.css";
import "../src/assets/mobile-css.css";



import FrontendHome from "./views/Frontend/FrontendHome";
import AboutUsView from "./views/Frontend/AboutUsView";
import AudioView from "./views/Frontend/AudioView";
import LightsView from "./views/Frontend/LightsView";
import SpecialFxView from "./views/Frontend/SpecialFxView";
import DancefloorView from "./views/Frontend/DancefloorView";
import PartnersView from "./views/Frontend/PartnersView";
import ContactView from "./views/Frontend/ContactView";
import SilentDiscoView from "./views/Frontend/SilentDiscoView";
import LandingspageView from "./views/Frontend/LandingspageView";
import ProductView from "./views/Shop/ProductPageView";
import ShoppingBagView from "./views/Shop/ShoppingBagView";
import CheckOutView from "./views/Shop/CheckoutView";
import ThankyouView from "./views/Shop/ThankYouView";
import DealProductView from "./views/Shop/DealProductPageView";



export default () => (
  <Router basename={process.env.REACT_APP_BASENAME || ""}>
    <Switch>
    
    <div>
    
   <Route exact path="/home" component={FrontendHome}/>
   <Route exact path="/over-ons" component={AboutUsView}/>
   <Route exact path="/audio" component={AudioView}/>
   <Route exact path="/licht" component={LightsView}/>
   <Route exact path="/special-fx" component={SpecialFxView}/>
   <Route exact path="/dansvloer" component={DancefloorView}/>
   <Route exact path="/partners" component={PartnersView}/>
   <Route exact path="/silent-disco" component={SilentDiscoView}/>
   <Route exact path="/contact" component={ContactView}/>
   <Route exact path="/landingspage" component={LandingspageView}/>

   <Route  path="/product" component={ProductView}/>
   <Link to="/pakket/:id"/>
   <Route  path="/pakket" component={DealProductView}/>
   <Route  path="/winkelmand" component={ShoppingBagView}/>
   <Route  path="/afrekenen" component={CheckOutView}/>
   <Route  path="/bedankt" component={ThankyouView}/>
   

 

  
  
   
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={withTracker(props => {
              return (
                <route.layout {...props}>
                  <route.component {...props} />
                </route.layout>
              );
            })}
          />
        );
      })}
    </div>
    </Switch>
  </Router>
);
