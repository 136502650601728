import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";


class PartnersComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
      

        };
    }




    render() {
        

        return (
     
        <Container fluid className={'mobile-padding-top-l-large'}>
            <Row className="background-color-fluid partners-row ">
                <Col sm={12} className="h2-light-center p-light-center ">
                    <h2><span className="red-slashes">//</span> PARTNERS</h2>
                    <p>
                   <b>Een overzicht van onze gewaardeerde partners met wie wij samenwerken.</b>
                    </p>
                 

                </Col>
                <Col sm={3} className="partner-col">
                    
                </Col>
                <Col sm={2} className="partner-col">
                    <img src={require('../../assets/images/dmsounds/partners/vip.svg')} />
                </Col>
                <Col sm={2} className="partner-col">
                    <img src={require('../../assets/images/dmsounds/partners/mg-partner.svg')} />
                </Col>
                <Col sm={2} className="partner-col">
                    <img src={require('../../assets/images/dmsounds/partners/jacobs.svg')} />
                </Col>
               

                
               
            </Row>
           
            
        </Container>

        );
    }
};

export default PartnersComponent;