import React from 'react';
import { Link } from 'react-router-dom';
import {Col, Row} from 'shards-react';
import { Container } from "shards-react";

class HeaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            winkels:true,
            aboutUs: false,
            contact: false,
            faq: false,
            inschrijving: false,
            webshops:false,
         
        };
    }


    toggleHamburger = () => {
        document.querySelector('.navbar-mobile').classList.toggle('showNav');
        document.querySelector('.ham').classList.toggle('showClose');
    }

    onClickMenu(menuItem){
        if(menuItem === 'home'){
            this.setState({
                winkels:true,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }else if(menuItem=== 'deelnemers'){
            this.setState({
                winkels:false,
                aboutUs: true,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }
        else if(menuItem=== 'recepten'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: true,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }
        else if(menuItem === 'aan-het-woord'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: true,
                inschrijving: false,
                webshops:false
            })
        }
        else if(menuItem === 'inschrijving'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: true,
                webshops:false
            })
        }
        else if(menuItem === 'webshops'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:true
            })
        }
    }
    listenScrollEvent = e => {
        //&&  window.innerWidth < 400
        if (window.scrollY > 100 ) {
         document.getElementById('header-container').style.backgroundColor = 'black';
         document.getElementById('header-container').style.marginTop = '0%';
        } else if(window.scrollY < 100) {
  
            console.log(window.scrollY)
            document.getElementById('header-container').style.backgroundColor = 'transparent';
            document.getElementById('header-container').style.marginTop = '3%';
        }
        console.log(window.scrollY)
      }

    componentDidMount(){
     window.addEventListener('scroll', this.listenScrollEvent)
        if(localStorage.getItem('menuActive') === 'home'){
            this.setState({
                winkels:true,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }else if(localStorage.getItem('menuActive') === 'deelnemers'){
            this.setState({
                winkels:false,
                aboutUs: true,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }
        else if(localStorage.getItem('menuActive') === 'recepten'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: true,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }
        else if(localStorage.getItem('menuActive') === 'aan-het-woord'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: true,
                inschrijving: false,
                webshops:false
            })
        }
        else if(localStorage.getItem('menuActive') === 'inschrijving'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: true,
                webshops:false
            })
        }
        else if(localStorage.getItem('menuActive') === 'webshops'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:true
            })
        }
    }

    render() {

        return (
            <Container fluid className="header-container" id="header-container">
                <Row className="header-row desktop-menu">
                    <Col sm={5} className="header-col">
                        <ul>
                           <li className="active"><Link to={'../over-ons'}>Over ons</Link></li>
                           <li> <Link to={'../audio'}>Audio</Link></li>
                            <li> <Link to={'../licht'}>Light</Link></li>
                            <li> <Link to={'../special-fx'}>Special FX</Link></li>
                        </ul>

                    </Col>
                    <Col sm={2}  className="header-col">
                        <Link to={'../home'}>
                        <img src={require('../../assets/images/dmsounds/logo.png')} />
                        </Link>
                    </Col>
                    <Col sm={5}  className="header-col">
                        <ul>
                            <li><Link to={'../dansvloer'}>Dansvloer</Link></li>
                            <li><Link to={'../silent-disco'}>Silent disco</Link></li>
                            <li><Link to={'../partners'}>Partners</Link></li>
                            <li><Link to={'../contact'}>Contact</Link></li>
                        </ul>
                    </Col>
                </Row>

                <Row className=" header-row mobile-menu">
                    <Col sm={12}>
                    <a href="../"> <img alt='Het logo van Beter Bij Ons' src={require('../../assets/images/dmsounds/logo.png')} className="mobile-logo-menu"/></a>

                    {Number(this.state.cartAmount) == 0?  null: <a className="mobile-shopping-cart" href="../winkelmand"><i class="fas fa-shopping-basket"></i>{Number(this.state.cartAmount) >0 ?<span className="amount-circle">{Number(this.state.cartAmount)}</span>:null}</a>}
                        {this.state.cartVisible ?
                        <div className="shopping-cart-overview-header">
                            <p>My list</p>
                            <ul>{this.state.bagItems.map((item)=>(
                                <li>{item.name}<span className="shopping-car-overview-price"> € {item.price.toFixed(2)}</span></li>
                            ))}
                                
                            </ul>
                            <a href={'../'} className="button-light">Verder winkelen</a>
                            <a href={'../winkelmand'} className="button-cta">Naar winkelmand</a>
                        </div>:null}
                        <button className="ham" onClick={() => this.toggleHamburger()}></button>
                        <nav className="navbar-mobile">

                            <ul>
                                <li><a className="menuLink"  href="../">Home</a></li>
                                <li><a className="menuLink"  href="../over-ons">Over ons</a></li>
                                <li><a className="menuLink"href="../audio">Audio</a></li>
                                <li><a className="menuLink" href="../licht">Light</a></li>
                                <li><a className="menuLink" href="../special-fx">Special FX</a></li>
                                <li><a className="menuLink" href="../dansvloer">Dansvloer</a></li>
                                <li><a className="menuLink" href="../silent-disco">Silent disco</a></li>
                                <li><a className="menuLink" href="../partners">Partners</a></li>
                                <li><a className="menuLink" href="../contact">Contact</a></li>

                      
                               
                            </ul>
                        </nav>
                    </Col>


        </Row>
 
                
        </Container>
        );
    }
}

export default HeaderComponent;
