import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';

import { Redirect } from 'react-router-dom';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import toast, { Toaster } from 'react-hot-toast';
import uuid from 'react-uuid'
import ReactStars from "react-rating-stars-component";
const mapStyle = {
    width: '100%',
    height: 800,
    position:'absolute'
}




class SilentDiscoComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           products:[]
      

        };
    }


    componentDidMount(){
        let ref = Firebase.database().ref('/clients/-33297fbik3bjfew/deals');
        ref.on('value' , snapshot => {
            this.setState({products:[]})
           
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();

             this.setState(prevState => ({
                        products: [...prevState.products, state]
                    })) 
            })
        })

       
    }

    
    

    render() {
        

        return (
     
        <Container fluid>
           
            <Row className="background-image-fluid silent-page product-page-top">
               
            <Col sm={12} className="h2-light-center p-light-center">
                    <h2><span className="red-slashes red-mobile">//</span> SILENT <span className="space-difference">Disco</span></h2>
            

                </Col>
                <Col sm={6} className="p-light-left silent-disco-p">
                    <p>
                    Silent Disco is al jaren een een bekend fenomeen op festivals en heeft zich mede hierdoor keer op keer laten bewijzen dat het echt sfeerverhogend is.<br/><br/>
                    Inmiddels hebben we ons assortiment uitgebreid tot ruim 450 hoofdtelefoons & 40 zenders, dankzij deze aantallen behoren wij tot een van de grotere leveranciers in onze regio.<br/><br/>
                    Óf u nu een livestream organiseert met 400 personen op 40 verschillende locaties, een zakelijke conferentie of gewoonweg thuis een feestje houdt met 8 personen, de toepassing van Silent Disco is oneindig! Van klein tot groot, u bent bij ons aan het juiste adres.
                    </p>
                    <a href="../product">
                    <Button className="button-red-left">BESTELLEN</Button>
                    </a>
                </Col>
                <Col sm={6} className={'silent-disco-image desktop-images-headphones'}>
                    <img src={require('../../assets/images/dmsounds/headphones-single-.png')} />
                 </Col>

               
             
            </Row>
            <Row className="background-color-fluid silent-disco-choose"> 
            <Col sm={12} className=" h2-light-center">
                            <h2>Pakketten met 1 zender</h2>
                </Col>
             {this.state.products.filter((item)=> item.receivers == 1).sort(function(a, b){return a.price === b.price ? 0 : b.price < a.price ? -1 : 1;}).slice(0,3).map((item,i)=>(
                <Col sm={4} className="Object-block large-block" style={{backgroundImage:`url(${item.img})`}}>
                <a href={"../pakket?id="+item.id}>
                    <Col sm={12}  className="object-block-inside">
                        <a href={"../pakket?id="+item.id}>{item.name.toUpperCase()} € {parseFloat(item.price).toFixed(2)}</a>
                    </Col>
                    </a>
                </Col>
             ))}
     
            </Row>
            <Row className="background-color-fluid silent-disco-choose"> 
            <Col sm={12} className=" h2-light-center">
                            <h2>Pakketten met 2 zenders</h2>
                </Col>
             
                {this.state.products.filter((item)=> item.receivers == 2).sort(function(a, b){return a.price === b.price ? 0 : b.price < a.price ? -1 : 1;}).slice(0,3).map((item,i)=>(
                <Col sm={4} className="Object-block large-block" style={{backgroundImage:`url(${item.img})`}}>
                <a href={"../pakket?id="+item.id}>
                    <Col sm={12}  className="object-block-inside">
                        <a href={"../pakket?id="+item.id}>{item.name.toUpperCase()} € {parseFloat(item.price).toFixed(2)}</a>
                    </Col>
                    </a>
                </Col>
             ))}
                
            </Row>
            <Row className="background-color-fluid silent-disco-choose"> 
            <Col sm={12} className=" h2-light-center">
                            <h2>Pakketten met 3 zenders</h2>
                </Col>
             
                {this.state.products.filter((item)=> item.receivers == 3).sort(function(a, b){return a.price === b.price ? 0 : b.price < a.price ? -1 : 1;}).slice(0,3).map((item,i)=>(
                <Col sm={4} className="Object-block large-block" style={{backgroundImage:`url(${item.img})`}}>
                <a href={"../pakket?id="+item.id}>
                    <Col sm={12}  className="object-block-inside">
                        <a href={"../pakket?id="+item.id}>{item.name.toUpperCase()} € {parseFloat(item.price).toFixed(2)}</a>
                    </Col>
                    </a>
                </Col>
             ))}
                
            </Row>
            
        
            <Row className="background-color-fluid custom-color-background silent-disco-specific">
            <Col sm={4} className="h2-light-left p-light-left">
                    <h2>ENJOY yourself,<br/>
We take care of the rest</h2>
                    <p>
                   Wij staan open voor de meest gekke uitdagingen, heeft u een voor ons? Neem dan direct contact met ons op. Samen gaan we kijken hoe wij de vraag kunnen beantwoorden en van uw event een gaaf feest kunnen maken.
                    </p>

                    <a href="../contact" className="button-red-left custom-left link-button">Contact</a>
                </Col>
                <Col sm={8} className="slot-col slot-col-right">
                  
                </Col>
               
            </Row>
        </Container>

        );
    }
};

export default SilentDiscoComponent;