import React from "react";
import FooterComponent from "../../components/Footer/FooterComponent";
import ContactComponent from "../../components/frontend/contactComponent";



import PartnersComponent from "../../components/frontend/partnersComponent";
import HeaderComponent from "../../components/Header/headerComponent";
import SubHeaderComponent from "../../components/Header/subHeaderComponent";


const ContactView = () => (
    <div>
<SubHeaderComponent/>
<HeaderComponent/>
    <ContactComponent />
<FooterComponent/>
    
    </div>
);

export default ContactView;
