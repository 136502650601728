import React from "react";
import FooterComponent from "../../components/Footer/FooterComponent";
import ContactComponent from "../../components/frontend/contactComponent";
import LandingspageComponent from "../../components/frontend/landingspageComponent";



import PartnersComponent from "../../components/frontend/partnersComponent";
import HeaderComponent from "../../components/Header/headerComponent";
import SubHeaderComponent from "../../components/Header/subHeaderComponent";


const LandingspageView = () => (
    <div>

    <LandingspageComponent />

    </div>
);

export default LandingspageView;
