import React from 'react';
import { Link } from 'react-router-dom';
import {Col, Row} from 'shards-react';
import { Container } from "shards-react";

class SubHeaderComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            winkels:true,
            aboutUs: false,
            contact: false,
            faq: false,
            inschrijving: false,
            webshops:false
         
        };
    }


    toggleHamburger = () => {
        document.querySelector('.navbar-mobile').classList.toggle('showNav');
        document.querySelector('.ham').classList.toggle('showClose');
    }

    onClickMenu(menuItem){
        if(menuItem === 'home'){
            this.setState({
                winkels:true,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }else if(menuItem=== 'deelnemers'){
            this.setState({
                winkels:false,
                aboutUs: true,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }
        else if(menuItem=== 'recepten'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: true,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }
        else if(menuItem === 'aan-het-woord'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: true,
                inschrijving: false,
                webshops:false
            })
        }
        else if(menuItem === 'inschrijving'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: true,
                webshops:false
            })
        }
        else if(menuItem === 'webshops'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:true
            })
        }
    }
    listenScrollEvent = e => {
        if (window.scrollY > 100) {
         document.getElementById('header-container').style.backgroundColor = 'black';
        } else if(window.scrollY < 100) {
            console.log(window.scrollY)
            document.getElementById('header-container').style.backgroundColor = 'transparent';
        }
        console.log(window.scrollY)
      }

    componentDidMount(){
     window.addEventListener('scroll', this.listenScrollEvent)
        if(localStorage.getItem('menuActive') === 'home'){
            this.setState({
                winkels:true,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }else if(localStorage.getItem('menuActive') === 'deelnemers'){
            this.setState({
                winkels:false,
                aboutUs: true,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }
        else if(localStorage.getItem('menuActive') === 'recepten'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: true,
                faq: false,
                inschrijving: false,
                webshops:false
            })
        }
        else if(localStorage.getItem('menuActive') === 'aan-het-woord'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: true,
                inschrijving: false,
                webshops:false
            })
        }
        else if(localStorage.getItem('menuActive') === 'inschrijving'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: true,
                webshops:false
            })
        }
        else if(localStorage.getItem('menuActive') === 'webshops'){
            this.setState({
                winkels:false,
                aboutUs: false,
                contact: false,
                faq: false,
                inschrijving: false,
                webshops:true
            })
        }
    }

    render() {

        return (
            <Container fluid className="sub-header-container ">
                <Row className="sub-header-row">
                    <Col sm={12} className="sub-header-col">
                       <p><i class="fas fa-check"></i> Vandaag besteld voor 12.00 besteld = vanavond in huis</p>
                       <p><i class="fas fa-check"></i> Verzending in NL / BE / DE</p>
                       <p><i class="fas fa-check"></i> Géén borg noodzakelijk</p>
                      
                       <p className="phone-numbers">+31 (0)6 424 649 90 | +31 (0)6 153 365 26</p>
                    </Col>
                </Row>
 
                
        </Container>
        );
    }
}

export default SubHeaderComponent;
