import React from "react";
import FooterComponent from "../../components/Footer/FooterComponent";


import DancefloorComponent from "../../components/frontend/dancefloorComponent";
import SilentDiscoComponent from "../../components/frontend/silentDiscoComponent";
import HeaderComponent from "../../components/Header/headerComponent";
import SubHeaderComponent from "../../components/Header/subHeaderComponent";


const SilentDiscoView = () => (
    <div>
<SubHeaderComponent/>
<HeaderComponent/>
    <SilentDiscoComponent />

    <FooterComponent/>
    </div>
);

export default SilentDiscoView;
