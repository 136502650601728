import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";


class SpecialFxComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
      

        };
    }




    render() {
        

        return (
     
        <Container fluid >
            <Row className="background-color-fluid ">
                <Col sm={7} className="h2-light-left p-light-left special-fx-col mobile-padding-top-large">
                    <h2><span className="red-slashes">//</span> SPECIAL FX</h2>
                    <p>
                   <b> Licht is een essentieel onderdeel om een bepaalde sfeer of ambiance te creeeren.
Het is de samenhang van kleuren, vormen, intensiteit en contrasten die een event maken. Of het nu gaat om de uitlichting van een object of de lichtshow voor een festival, indoor of outdoor, bij ons bent u aan het juiste adres.</b>
                    </p>
                    <p>
                    Al onze intelligente verlichting is computergestuurd en aan de hand van moderne visualisatie software kunnen wij een lichtplan alvorens een project met de klant delen en zodanig eenvoudig aanpassen waar nodig.
                    </p>


                </Col>
                <Col sm={5} className="special-fx-right-col p-light-left special-fx-col-css">
                 <p>
                 Een greep uit ons assortiment:
                 <ul>
                     <li><span className="red-slashes">//</span>Koud vuur en sparkulars</li>
                     <li><span className="red-slashes">//</span>CO2 jets / guns</li>
                     <li><span className="red-slashes">//</span>Confetti shooters / blasters / stadium shots</li>
                     <li><span className="red-slashes">//</span>Rook machines / low fog machines</li>
                     <li><span className="red-slashes">//</span>Lasers (single colour-full colour</li>
                 </ul>
                 </p>

                </Col>
  
               
            </Row>

            
           
           
        </Container>

        );
    }
};

export default SpecialFxComponent;