import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";
import toast, { Toaster } from 'react-hot-toast';
import Firebase from 'firebase';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Redirect } from 'react-router-dom';
class DealProductpageComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id:'',
            productAmount:1,
            currenShoppingBag:[],
            winkelier:'',
            plaats:'',
            product:[],
            variations: false,
            variation:[],
            price:0,
            images:[],
            stock:0,
            variantChosen:'',
            information:[],
            products:[],
            noProducts:false,
            productImages:[],
            priceSet:false,
            variantId:'',
            officialName:'',
            description:'',
            imageoverView:false,
            cantShip:true,
            productInformation:[],
            selectedDate:new Date(),
            redirect:false
      

        };
    }


    Products = [];
    splittedItem = [];

    executeScroll = () => this.anyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })




    componentDidMount(){
      
       
               


       this.splittedItem = this.props.id.split("?");

        this.setState({
            id: this.splittedItem[1].split("id=")[1],
        })

        if(localStorage.getItem('shoppingBag') != null){
            this.Products = JSON.parse(localStorage.getItem('shoppingBag')) ;
            localStorage.setItem('shoppingCartAmount', this.Products.length)
        }

        let products = Firebase.database().ref('/clients/-33297fbik3bjfew/deals/'+this.splittedItem[1].split("id=")[1]);
        products.on('value' , snapshot => {
            let state = snapshot.val();

            this.setState({
                product: state
            })

            console.log(state)

            
      
           
        })



    }

    AddToCart(){
        console.log(this.state.selectedDate)
        console.log()
    
    
 
    
            this.Products.push({page:'deal',id: this.splittedItem[1].split("id=")[1],headphones:this.state.product.headphones,receivers:this.state.product.receivers,date:new Date(this.state.selectedDate)});
        
        

      


        localStorage.setItem('shoppingBag',JSON.stringify(this.Products));
        localStorage.setItem('shoppingCartAmount', this.Products.length);


        const reaction = ()=>( <div> Het product is succesvol toegevoegd! <a href={'../winkelmand'}> Bekijk</a></div>)
        toast.success(reaction);
        this.setState({
            redirect:true
        })
 
    }


    minProductAmount(amount, selectedProduct){
        console.log('groter dan')
        if((amount -1) >0){
console.log('groter dan')
        let newAmount = amount - 1;

        for(let i = 0; this.state.products.length; i++){
            if(selectedProduct.id == this.state.products[i].id){

                    let products = [...this.state.products];
                    // 2. Make a shallow copy of the item you want to mutate
                    let product = {...this.state.products[i]};
                    console.log(product)
                    // 3. Replace the property you're intested in
                    product.amount = newAmount;
                   
                    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                    products[i] = product;
                    // 5. Set the state to our new copy
                    this.setState({products});

                    return
            }

        }
    }
        
    }

    addProductAmount(amount, selectedProduct){
      if(this.state.product.activeVariations ){
       if(amount +1 < this.state.stock) {
        let newAmount = amount +1;

        for(let i = 0; this.state.products.length; i++){
            if(selectedProduct.id == this.state.products[i].id){

                    let products = [...this.state.products];
                    // 2. Make a shallow copy of the item you want to mutate
                    let product = {...this.state.products[i]};
                    console.log(product)
                    // 3. Replace the property you're intested in
                    product.amount = newAmount;
                   
                    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                    products[i] = product;
                    // 5. Set the state to our new copy
                    this.setState({products});

                    return
            }

        }
       }

      }else{
        if(amount +1 < this.state.product.stock) {
            let newAmount = amount +1;
    
            for(let i = 0; this.state.products.length; i++){
                if(selectedProduct.id == this.state.products[i].id){
    
                        let products = [...this.state.products];
                        // 2. Make a shallow copy of the item you want to mutate
                        let product = {...this.state.products[i]};
                        console.log(product)
                        // 3. Replace the property you're intested in
                        product.amount = newAmount;
                       
                        // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                        products[i] = product;
                        // 5. Set the state to our new copy
                        this.setState({products});
    
                        return
                }
    
            }
           }
           
            
      } 
        

    }

    selectDate(event){
        this.setState({
            selectedDate:event
        })

        console.log(event)
 
    }

    startDateOld = new Date();
    endDateOld = new Date();
    render() {
        if( this.state.redirect){
            return <Redirect to={'/winkelmand'} /> 
           }
        

        return (
     
            <Container fluid>
                    
            <Row  className="background-color-fluid contact-row mobile-padding-top-xxl-large product-page-top">
                <Col sm={6} className="shop-highlighted-product-image shop-detail-image">
                   <img src={this.state.product.img} />
                </Col>
                <Col sm={6} className="shop-product-detail h2-light-left p-light-left ">
                    <h2>{this.state.product.name}</h2>
                    <p dangerouslySetInnerHTML={{ __html:this.state.product.desc}}></p>

                
                    <p><br/><span className="price-product-detail">€ {parseFloat(this.state.product.price).toFixed(2)}</span>  incl. BTW</p>
                    <br/>  <br/>
                    <div className='amount-set'>
                    <p><b>Aantal hoofdtelefoons</b></p>    
                    <span className="amount-add">{this.state.product.headphones}  </span>   
                    </div>
                    <div className='amount-set'>
                    <p><b>Aantal zenders</b></p>    
                    <span className="amount-add"> {this.state.product.receivers} </span>   
                    </div>
                    <p className='select-date-p'><b>Selecteer datum</b></p>    
                    <Calendar locale={"nl-NL"} value={this.state.selectedDate}  onChange={(event)=> this.selectDate(event)}  selectRange={false} returnValue={"start"} minDate={new Date()}/>
<br/>
                    <Button  className="add-to-shoppingcart-half" onClick={()=>this.AddToCart()}>In winkelmand</Button>

                </Col>
            </Row>

            <Row>
                <Col sm={12} className="shop-summary-inside">
                    {this.state.images.map((image)=>(
                        <img src={image.url} />
                    ))}
                


                </Col>
                
            </Row>
        </Container>

        );
    }
};

export default DealProductpageComponent;