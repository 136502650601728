import React from "react";
import FooterComponent from "../../components/Footer/FooterComponent";


import DancefloorComponent from "../../components/frontend/dancefloorComponent";
import HeaderComponent from "../../components/Header/headerComponent";
import SubHeaderComponent from "../../components/Header/subHeaderComponent";


const DancefloorView = () => (
    <div>
<SubHeaderComponent/>
<HeaderComponent/>
    <DancefloorComponent />

    <FooterComponent/>
    </div>
);

export default DancefloorView;
