import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";


class AudioComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
      

        };
    }




    render() {
        

        return (
     
        <Container fluid>
            <Row className="background-color-fluid custom-bottom">
                <Col sm={6} className="h2-light-left p-light-left audio-col">
                    <h2><span className="red-slashes">//</span> AUDIO</h2>
                    <p>
                   <b> Audio is het fundament van vrijwel iedere festiviteit. De basis moet natuurlijk
goed zijn. Daarom werken wij enkel met gerenomeerde merken als Martin Audio, DB Technologies, QSC, Midas, Shure, Sennheiser & Pioneer.</b>
                    </p>
                    <p>
                    Naast het gebruik van professionele apparatuur beschikken wij over de benodigde kennis om keer op keer maatwerk te leveren met een voortdurend hoge kwaliteit.
Maatwerk geluidsinstallaties voor de beste geluidservaring op ieder event
                    </p>


                </Col>
                <Col sm={6} className="audio-images">
                   <img src={require('../../assets/images/dmsounds/audio-images.png')} />
                </Col>
               
            </Row>

            <Row className="background-color-fluid custom-bottom">
            <Col sm={2} className="partner-col">
                    <img src={require('../../assets/images/dmsounds/brands/shure.svg')} />
                </Col>
                <Col sm={2} className="partner-col">
                    <img src={require('../../assets/images/dmsounds/brands/logo-2.svg')} />
                </Col>
                <Col sm={2} className="partner-col">
                    <img src={require('../../assets/images/dmsounds/brands/pioneer.svg')} />
                </Col>
                <Col sm={2} className="partner-col">
                    <img src={require('../../assets/images/dmsounds/brands/senheiser.svg')} />
                </Col>
                <Col sm={2} className="partner-col">
                    <img src={require('../../assets/images/dmsounds/brands/qsc.svg')} />
                </Col>
                <Col sm={2} className="partner-col">
                    <img src={require('../../assets/images/dmsounds/brands/shure.svg')} />
                </Col>
            </Row>
           
           
        </Container>

        );
    }
};

export default AudioComponent;