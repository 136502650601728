import React from "react";
import FooterComponent from "../../components/Footer/FooterComponent";

import AboutUsComponent from "../../components/frontend/about-us";
import AudioComponent from "../../components/frontend/audioComponent";
import HeaderComponent from "../../components/Header/headerComponent";
import SubHeaderComponent from "../../components/Header/subHeaderComponent";
import CheckoutComponent from "../../components/shop/CheckoutComponent";
import ThankyouComponent from "../../components/shop/TankyouComponent";



const ThankyouView = () => (
    <div>
<SubHeaderComponent/>
<HeaderComponent/>
    <ThankyouComponent />
<FooterComponent/>
    
    </div>
);

export default ThankyouView;
