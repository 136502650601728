import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";


class DancefloorComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
      

        };
    }




    render() {
        

        return (
     
        <Container fluid className={'mobile-padding-top'}>
            <Row className="background-color-fluid dancefloor-col">
                <Col sm={6} className="h2-light-left p-light-left dance-floor-text ">
                    <h2><span className="red-slashes">//</span> DANSVLOER</h2>


                </Col>
                <Col sm={6} className="lights-usps p-light-left dancefloor-col-text">
                 <p>
                    <b>Wil jij jouw bruiloft, bedrijfsfeest of evenement een extra dementie geven? Huur een betaalbare verlichte-vloer!</b>
                    <br/>          <br/>
                 Een disco dansvloer is de ultieme oplossing om het feest compleet te maken! Wij leveren flinterdunne verlichte dansvloeren die wij speciaal ontwikkeld hebben om elk type feest of evenement om te toveren tot een echte spektakel! Wij leveren de dansvloeren in verschillende maten en variaties.
                 <br/>          <br/>
                 Met maarliefst 170 pixels per vierkante meter zijn wij met uitstek de enige in de regio welke aan deze hoge kwaliteit kunnen voldoen, volledig aanstuurbaar met iPad, Remote control óf stand alone!
                 </p>
                 <a href="../contact" className="button-red-left custom-left link-button">Contact</a>


                </Col>
  
               
            </Row>

            
           
           
        </Container>

        );
    }
};

export default DancefloorComponent;