import React from "react";
import FooterComponent from "../../components/Footer/FooterComponent";

import AboutUsComponent from "../../components/frontend/about-us";
import HeaderComponent from "../../components/Header/headerComponent";
import SubHeaderComponent from "../../components/Header/subHeaderComponent";



const AboutUsView = () => (
    <div>
<SubHeaderComponent/>
<HeaderComponent/>
    <AboutUsComponent />
<FooterComponent/>
    
    </div>
);

export default AboutUsView;
