import React from "react";
import FooterComponent from "../../components/Footer/FooterComponent";



import PartnersComponent from "../../components/frontend/partnersComponent";
import HeaderComponent from "../../components/Header/headerComponent";
import SubHeaderComponent from "../../components/Header/subHeaderComponent";


const PartnersView = () => (
    <div>
<SubHeaderComponent/>
<HeaderComponent/>
    <PartnersComponent />

    <FooterComponent/>
    </div>
);

export default PartnersView;
