import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";


class Lightsomponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
      

        };
    }




    render() {
        

        return (
     
        <Container fluid>
            <Row className="background-image-fluid lights-col">
                <Col sm={6} className="h2-light-left p-light-left audio-col">
                    <h2><span className="red-slashes">//</span> LIGHTS</h2>
                    <p>
                   <b> Licht is een essentieel onderdeel om een bepaalde sfeer of ambiance te creeeren.
Het is de samenhang van kleuren, vormen, intensiteit en contrasten die een event maken. Of het nu gaat om de uitlichting van een object of de lichtshow voor een festival, indoor of outdoor, bij ons bent u aan het juiste adres.</b>
                    </p>
                    <p>
                    Al onze intelligente verlichting is computergestuurd en aan de hand van moderne visualisatie software kunnen wij een lichtplan alvorens een project met de klant delen en zodanig eenvoudig aanpassen waar nodig.
                    </p>


                </Col>
                <Col sm={6} className="audio-images">
                 
                </Col>
                <Col sm={4}>

</Col>
<Col sm={5} className="quote-about-us light-page">
<i class="fas fa-quote-right"></i>
    <h3>Kleren maken de man & <br/>
licht bepaald De ambiance</h3>
</Col>
               
            </Row>

            
           
           
        </Container>

        );
    }
};

export default Lightsomponent;