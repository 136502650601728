import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';

class ShoppingbagComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
            productsAdded: false,
            bagItems:[],
            productImages:[],
            allTotals:0,
            backUpPrice:0,
            companyImages:[],
            recalcPrice:false,
            timeslotsOwners:[],today:new Date()+1,
            saleActive:false,
            showSaleText:false,
            filledCoupon:''

        };
    }
    products = [];

    
    

    checkProducts(){

        this.setState({bagItems:[]})
        this.setState({
            allTotals: 0,
            backUpPrice:0
        })

        this.totalPrice =0;
     
        this.products = JSON.parse(localStorage.getItem('shoppingBag')) ;
        localStorage.setItem('shoppingCartAmount', this.products.length)
     
             for(let i = 0; i< this.products.length; i++){

               if(this.products[i].page =='deal'){

    

                let products = Firebase.database().ref('/clients/-33297fbik3bjfew/deals/'+this.products[i].id);
              products.on('value' , snapshot => {
                    let state = snapshot.val();

                    let price= state.price;
                 
                
                    let addedProduct = {name:state.name,headphones:this.products[i].headphones,receivers:this.products[i].receivers,price:price,id:this.products[i].id,selectedDate:this.products[i].date}
                  //  let totalAmount = parseFloat() *this.products[i].amount;

                  console.table(addedProduct)
                   
                    this.setState(prevState =>({
                        bagItems: [...prevState.bagItems,addedProduct],
                      
                 
                    }))
               
                    this.calculateTotel((parseFloat(price.toString().replace(/,/g, '.'))))
          
                
                   
                   
                })
          
            }else if(this.products[i].page =='custom'){
                let addedProduct = {name:'Eigen samenstelling',headphones:this.products[i].headphones,receivers:this.products[i].receivers,price:this.products[i].price,id:this.products[i].id,selectedDate:this.products[i].date}
           

                  console.table(addedProduct)
                   
                    this.setState(prevState =>({
                        bagItems: [...prevState.bagItems,addedProduct],
                      
                 
                    }))
               
                    this.calculateTotel((parseFloat(this.products[i].price.toString().replace(/,/g, '.'))))
          
            }
            }

            
              
    }
    

    totalPrice = 0;

    calculateTotel(newPayment){


       this.setState({
            allTotals: this.state.allTotals+ newPayment,
            backUpPrice: this.state.allTotals+ newPayment
        })

        this.totalPrice = this.totalPrice + newPayment;

        localStorage.setItem('total',(this.totalPrice).toFixed(2))



    }
    setBagItems(){

       localStorage.setItem('bagItems',JSON.stringify( this.state.bagItems))
    }
startFunction= async() =>{
    if(localStorage.getItem('shoppingBag') != null){
        this.products =[];
         await this.checkProducts();
         this.setState({
             productsAdded:true
         })


         setTimeout(() => {
             
this.setState({allTotals:this.state.allTotals})
this.setBagItems();
         },2000);
       
         
     }
}
 

    componentDidMount= async() =>{

        localStorage.setItem('coupon','not-active');
        this.setState({
            filledCoupon: ''
        })

        
        window.scrollTo(0, 0);
        this.startFunction();
     
    }

    deleteFromShoppingbag(product){
        this.totalPrice=0;
        


         {/*}   if(this.recalcPrice == true){
                let newPrice= parseFloat( this.totalPrice).toFixed(2) - (parseFloat(product.price)*product.amount).toFixed(2);
                this.setState({
                    bagItems: this.state.bagItems.filter((item)=> item.id !== product.id),
                   
                  
                })
   
                              
     
            }else{
                this.setState({
                    bagItems: this.state.bagItems.filter((item)=> item.id !== product.id),
                    allTotals:parseFloat( this.totalPrice).toFixed(2) - (parseFloat(product.price)*product.amount).toFixed(2),
                  
                })
     
            }

          

            this.setState({
                recalcPrice:true
              })
            */}


       

this.setState({
    bagItems:[],
    allTotals:0,
    recalcPrice:true
})

        localStorage.setItem('shoppingBag', JSON.stringify(JSON.parse(localStorage.getItem('shoppingBag')).filter((item)=> item.id !== product.id)))
        localStorage.setItem('bagItems', JSON.stringify(JSON.parse(localStorage.getItem('bagItems')).filter((item)=> item.id !== product.id)))

      setTimeout(() => {
     // localStorage.setItem('total',(this.state.allTotals*100).toFixed(0))
     this.checkProducts();
    }, 1000);
  

       
    }

    checkCoupon(coupon){

        this.setState({
            filledCoupon: coupon
        })

        if(coupon.toUpperCase() == 'IK-SHOP-STREEKPRODUCTEN'){
            let calcSale = ((this.state.allTotals/100)*5);
            let newTotal = this.state.allTotals-calcSale;

           console.log(this.state.allTotals)
           console.log(calcSale)
           console.log(newTotal)

            this.setState({
                recalcPrice:true,
                allTotals: newTotal,
                saleActive:true,
                showSaleText:true
            })

 
            console.log(this.totalPrice)

            localStorage.setItem('total',(newTotal*100).toFixed(0))
            localStorage.setItem('coupon','active')

        }else if(this.state.saleActive && coupon.toUpperCase() != 'IK-SHOP-STREEKPRODUCTEN' ){
            
            let newTotal = this.state.backUpPrice;

            this.setState({
                recalcPrice:true,
                allTotals: newTotal,
                saleActive:false,
                showSaleText:false
            })

 
            console.log(this.totalPrice)

            localStorage.setItem('total',(newTotal*100).toFixed(0))
            localStorage.setItem('coupon','not-active')
        }

      
    }

    componentDidUpdate(){
       
       /* this.setState({
            showSaleText:false,
        })*/
    }


    changeValue(id, amount){

        if(amount > 0){

  

        this.state.bagItems.map((item,i)=>{
            if(item.id == id){
                let bagItems = [...this.state.bagItems];
                let bagItem = {...this.state.bagItems[i]};
                bagItem.amount = Number(amount);

          
       
                
                bagItems[i] = bagItem

                this.setState({bagItems})
            }
        })

        setTimeout(() => {
             
       
               

        localStorage.removeItem('shoppingBag');
        localStorage.removeItem('bagItems');
        localStorage.setItem('shoppingBag',JSON.stringify(this.state.bagItems));


        this.startFunction();
    },100);
}

    }




    render() {
        

        return (
     <div>
                    <Container fluid>
                    
                        <Row className="background-color-fluid thank-you-row mobile-padding-top-xxl-large">
                            <Col sm={12} className="checkout-detail shopping-bag h2-light-left">
                            <h2>Mijn feestlijst</h2>
                            {this.state.productsAdded?
                            <span>
                               <ul className="checkout-shoppinglist">
                                   {this.state.bagItems.map((item)=>(
                                     
                                        <li><span className="product-title-shoppinglist">{item.name}</span><span className="firsttimeslot">{(new Date(item.selectedDate)).getDate()+'-'+((new Date(item.selectedDate)).getMonth()+1)+'-'+(new Date(item.selectedDate)).getFullYear()} </span><span className="product-price-shoppinglist">€ {item.price.toFixed(2)}</span> <span className="delete-price-shoppinglist"><i onClick={()=>this.deleteFromShoppingbag(item)} class="fas fa-trash"></i></span></li>
                                                          
                                   ))}
                                  
                                   
                               </ul>
                               <span className="total-shopping-bag p-light-left">
                               <hr/>

                          {this.state.recalcPrice?  <p>Subtotaal <span className="checkout-shoppinglist-total">€ {this.state.backUpPrice.toFixed(2)}</span></p>: <p>Subtotaal <span className="checkout-shoppinglist-total">€ {this.totalPrice.toFixed(2)}</span></p>}
                          {this.state.recalcPrice?  <p>Kortingscode <span className="checkout-shoppinglist-total"><input className="coupon-code" type="text" value={this.state.filledCoupon} onChange={(event)=>this.checkCoupon(event.target.value)}/></span></p>: <p>Kortingscode <span className="checkout-shoppinglist-total"><input className="coupon-code" type="text" value={this.state.filledCoupon}  onChange={(event)=>this.checkCoupon(event.target.value)}/></span></p>}
                          {this.state.showSaleText?  <p className="coupon-code-mobile">Korting<span className="checkout-shoppinglist-total ">5%</span></p>: <p className="coupon-code-mobile">Korting <span className="checkout-shoppinglist-total">-</span></p>}
                              {/*} <p>Aantal afhaallocaties <span className="checkout-shoppinglist-total">2</span></p>*/}
                              {this.state.recalcPrice? <p><b>Totaal</b><span className="checkout-shoppinglist-total"><b>€ {this.state.allTotals.toFixed(2)}</b></span></p>:<p><b>Totaal</b><span className="checkout-shoppinglist-total"><b>€ {this.totalPrice.toFixed(2)}</b></span></p>}
                               <a className="pay-bill-button" href={"/afrekenen"}>Naar bestellen</a></span>
                               </span>
                                :
                                <span>Je hebt momenteel geen producten in je winkelmand</span>}
                              {/*} <p>Subtotaal <span className="checkout-shoppinglist-total">€ {this.state.recalcPrice ? this.state.allTotals.toFixed(2): this.totalPrice.toFixed(2)}</span></p>
                               <p>Aantal afhaallocaties <span className="checkout-shoppinglist-total">2</span></p>
                               <p><b>Totaal</b><span className="checkout-shoppinglist-total"><b>€ {this.state.recalcPrice ? this.state.allTotals.toFixed(2): this.totalPrice.toFixed(2)}</b></span></p>
                               <Link className="pay-bill-button" to={"/afrekenen"}>Naar bestellen</Link></span>
                               </span>
                                :
                                <span>Je hebt momenteel geen producten in je winkelmand</span>}*/}
                            </Col>
                            
                        </Row>
            
           
            
        </Container>
        </div>

        );
    }
};

export default ShoppingbagComponent;