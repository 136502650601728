import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";

import Countdown from './Countdown.js';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import toast, { Toaster } from 'react-hot-toast';



class LandingspageComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
 

        };
    }


    
    

    render() {
        

        return (
     
        <Container fluid className="landings-page">   
     
          <Row className="ladings-banner-row">    
          <Col sm={2} className="landingspage-image">
          <img alt='Het logo van Beter Bij Ons' src={require('../../assets/images/dmsounds/logo.png')}/>
            </Col>
          
          <Col sm={12}>
              <h1>We are coming very soon!</h1>
               <Countdown date={`2021-12-24T12:00:00`} />
               <br/>
               <h3>Volg ons alvast</h3>
               <span className="social-media-landings"><a href="https://www.facebook.com/dmsoundselsloo"><i class="fab fa-facebook-square"></i></a>   <a href="https://www.instagram.com/dmsounds_/"> <i class="fab fa-instagram"></i></a></span>
            </Col>
            </Row>

        </Container>

        );
    }
};

export default LandingspageComponent;