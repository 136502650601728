import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";


class AboutUsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
      

        };
    }




    render() {
        

        return (
     
        <Container fluid className={'mobile-padding-top'}>
            <Row className="background-color-fluid about-us-row">
                <Col sm={6} className="h2-light-left p-light-left">
                    <h2><span className="red-slashes">//</span> OVER DMSOUNDS</h2>
                    <p>
                   <b> DM Sounds is een jong, dynamisch en snel groeiend bedrijf ontstaan uit vriendschap, gevormd door ambitie, gerund met passie en gespecialiseerd in de verhuur & verkoop van licht, geluid en special FX.</b>
                    </p>
                    <p>
                    Door inmiddels meer dan 12 jaar ervaring binnen onze sector, het constant blijven anticiperen op nieuwe trends binnen onze dynamische sector en het aangaan van exclusieve partnerschappen zijn wij een unieke partner voor de verzorging van zowel privé, zakelijke en publieke evenementen.
                    </p>


                </Col>
                <Col sm={6} className="h2-light-left p-light-left custom-height">
                    <p>
                    Onze exclusieve partnerschappen zorgen voor een unieke service. Wanneer een event plaats vindt verzorgen wij de volledige opbouw, afbouw en eventuele bediening van apparatuur. Ook last-minute service is mogelijk. Wil je toch nog iets extra’s, dan kan er heel veel geregeld worden!
                    </p>
                    <p>
                    Bij alle events staan we voor vakbekwaamheid en een eerlijk advies met als doel een onvergetelijke ervaring te creëeren voor de bezoekers. Kortom,
de beste allround keuze in audiovisuele beleving op topniveau.. dat is DMSOUNDS.
                    </p>
                </Col>
               
            </Row>
            <Row className="background-image-fluid about-us">
               
                    <Col sm={1}>

                    </Col>
                    <Col sm={5} className="quote-about-us">
                    <i class="fas fa-quote-right"></i>
                        <h3>Ontstaan uit vriendschap,<br/>
Gevormd door ambitie<br/>
En gerund met passie.</h3>
                    </Col>
            
            </Row>
            <Row className="background-color-fluid">
                <Col sm={12} className="h2-light-center">
                    <h2>PARTNERS</h2>
                </Col>
                <Col sm={3} className="partner-col">
                    
                    </Col>
                    <Col sm={2} className="partner-col">
                        <img src={require('../../assets/images/dmsounds/partners/vip.svg')} />
                    </Col>
                    <Col sm={2} className="partner-col">
                        <img src={require('../../assets/images/dmsounds/partners/mg-partner.svg')} />
                    </Col>
                    <Col sm={2} className="partner-col">
                        <img src={require('../../assets/images/dmsounds/partners/jacobs.svg')} />
                    </Col>
            </Row>
           
        </Container>

        );
    }
};

export default AboutUsComponent;