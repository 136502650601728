import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button , Container} from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { StaticRouter } from 'react-router';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';

import { Redirect } from 'react-router-dom';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import toast, { Toaster } from 'react-hot-toast';
import uuid from 'react-uuid'
import ReactStars from "react-rating-stars-component";
const mapStyle = {
    width: '100%',
    height: 800,
    position:'absolute'
}




class FrontendHomeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
            redirect:false,
            order:false

        };
    }


    componentDidMount(){


       
    }


    

    render() {
        if( this.state.redirect){
            return <Redirect to={'/contact'} /> 
           }
      
        

        return (
     
        <Container fluid>
            <Row className="background-image-fluid intro">
                <Col sm={12} className="h1-light-center ">
                    <h1>MAKING EVENTS<br/>
                    MEMORABLE.</h1>
                    <Button onClick={()=>this.setState({redirect:true})} className="button-light-center red-hover-color">Offerte aanvragen</Button>
                </Col>
                <Col sm={12}  className="bottom-text">
                    <span> Scroll</span>
                </Col>
            </Row>
            <Row className="background-color-fluid">
                <Col sm={3} className="intro-hire-col">
                    <Row>
                        <a href="../silent-disco">
                            <Col sm={12} className="intro-hire-object silent-disco-object h2-light-left">
                                <h2>Silent<br/>disco</h2>
                                <img src={require('../../assets/images/dmsounds/silent-disco-intro.png')}/>
                            </Col>
                        </a>
                    </Row>
                   
                </Col>
                <Col sm={3} className="intro-hire-col">
                    <Row>
                        <a href="../audio">
                            <Col sm={12} className="intro-hire-object audio-object  h2-light-left">
                                <h2>Audio<br/></h2>
                                <img src={require('../../assets/images/dmsounds/audio-intro.png')}/>
                            </Col>
                        </a>
                    </Row>
                   
                </Col>
                <Col sm={3} className="intro-hire-col">
                    <Row>
                        <a href="../licht">
                            <Col sm={12} className="intro-hire-object light-object  h2-light-left">
                                <h2>Licht<br/></h2>
                                <img src={require('../../assets/images/dmsounds/licht-intro.png')}/>
                            </Col>
                        </a>
                    </Row>
                   
                </Col>
                <Col sm={3} className="intro-hire-col">
                    <Row>
                        <a href="../special-fx">
                            <Col sm={12} className="intro-hire-object special-fx-object h2-light-left">
                                <h2>Special<br/>FX</h2>
                                <img src={require('../../assets/images/dmsounds/special-fx-intro.png')}/>
                            </Col>
                        </a>
                    </Row>
                   
                </Col>

                <Col sm={12}>
               {/*} <Button className="button-red-center">BEKIJK ONS GEHELE AANBOD</Button>*/}
                </Col>
            </Row>
            <Row className="background-image-fluid silent-usps">
               
                <Col sm={12}>
                    <h2 className="cta-h2-left-white">Silent</h2>
                    <h2 className="cta-h2-right-outline">Disco</h2>

                </Col>
                <Col sm={2}>

                </Col>
                <Col sm={10} className="usps-list">
                    <ul>
                        <li><span className="red-slashes">//</span> Voor grote groepen</li>
                        <li><span className="red-slashes">//</span> Volume regeling</li>
                        <li><span className="red-slashes">//</span> Snel geleverd</li>
                        <li><span className="red-slashes">//</span> 40 zenders</li>
                    </ul>
                    <br/>
                    <br/>
                    <a href="../silent-disco" className="button-red-left link-button">BESTELLEN</a>
                </Col>
                <Col sm={2}>

                </Col>
            </Row>
            <Row className="background-image-fluid social-media">
            <Col sm={3}>

</Col>
                <Col sm={6}  className="social-media-block  h2-light-center">
                    <h2>VOLG ONS OP SOCIAL MEDIA</h2>
                    <p><a href="https://www.instagram.com/silentdiscobenelux/"><i class="fab fa-instagram"></i></a> <a href="https://www.facebook.com/silentdiscobenelux"><i class="fab fa-facebook-square"></i></a></p>
                </Col>
                <Col sm={3}>

                </Col>
            </Row>
            <Row className="background-color-fluid">
                <Col sm={12} className="h2-light-center">
                    <h2>PARTNERS</h2>
                </Col>
                <Col sm={3} className="partner-col">
                    
                </Col>
                <Col sm={2} className="partner-col">
                    <img src={require('../../assets/images/dmsounds/partners/vip.svg')} />
                </Col>
                <Col sm={2} className="partner-col">
                    <img src={require('../../assets/images/dmsounds/partners/mg-partner.svg')} />
                </Col>
                <Col sm={2} className="partner-col">
                    <img src={require('../../assets/images/dmsounds/partners/jacobs.svg')} />
                </Col>
            </Row>
            <Row className="background-color-fluid custom-color-background">
                <Col sm={4} className="h2-light-left p-light-left">
                    <h2>ENJOY yourself,<br/>
We take care of the rest</h2>
                    <p>
                   Wij staan open voor de meest gekke uitdagingen, heeft u een voor ons? Neem dan direct contact met ons op. Samen gaan we kijken hoe wij de vraag kunnen beantwoorden en van uw event een gaaf feest kunnen maken.
                    </p>

                    <a href="../contact" className="button-red-left custom-left link-button">Contact</a>
                </Col>
                <Col sm={8} className="slot-col slot-col-right">
                  
                </Col>
               
            </Row>
        </Container>

        );
    }
};

export default FrontendHomeComponent;